import styled from '@emotion/styled';
import { FixedGrid, FixedGridItem, Gutter, ITheme } from '../../../../ui';
import { theme } from '../../../shared/styles/theme';
import Responsive from '../../../../ui/utils/Responsive';

interface IStyledFlexShortcutGridProps {
	number: number;
}
export const StyledFlexShortcutGrid = styled(FixedGrid)<
	IStyledFlexShortcutGridProps,
	ITheme
>`
	width: 100%;
	height: 100%;
	gap: ${theme.grid?.gutter?.horizontal?.default};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		flex-wrap: wrap;
		align-content: space-between;
		gap: ${theme.grid?.gutter?.horizontal?.mobile};
		height: ${({ number }) =>
			number <= 4 ? '155px' : number <= 6 ? '240px' : '320px'};
	}
`;

interface IStyledFlexShortcutItem {
	number: number;
}

export const StyledFlexShortcutItem = styled(FixedGridItem)<
	IStyledFlexShortcutItem,
	ITheme
>`
	width: ${({ number }) =>
		`${
			number <= 4
				? `calc(
				(100% - ${number} * var(${Gutter.cssVariableName.horizontal})) / ${number} - ${theme.grid?.gutter?.horizontal?.default}
			)`
				: number > 4 && number < 7
				? `calc((100% - 3 * var(${Gutter.cssVariableName.horizontal})) / 3 - ${theme.grid?.gutter?.horizontal?.mobile} )`
				: `calc(
		(100% - 4 * var(${Gutter.cssVariableName.horizontal})) / 4 - ${theme.grid?.gutter?.horizontal?.default}
	)`
		}`};
	flex-grow: 1;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: calc(
			(100% - 2 * var(${Gutter.cssVariableName.horizontal})) / 2 -
				${theme.grid?.gutter?.horizontal?.default} / 2
		);
		flex-grow: 0;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		width: calc(
			(100% - 2 * var(${Gutter.cssVariableName.horizontal})) / 2 -
				${theme.grid?.gutter?.horizontal?.mobile} / 2
		);
		flex-grow: 0;
	}
`;

export const StyledShimmerShortcutItem = styled(StyledFlexShortcutItem)``;
