import styled from '@emotion/styled';
import { H3, ITheme, Link } from '../../../../ui';
import { Color } from '../../../shared/styles/Color';
import themed from '../../../../ui/themer/themed';
import Responsive from '../../../../ui/utils/Responsive';
import { theme } from '../../../shared/styles';

export const StyledShortcut = styled(Link)<{}, ITheme>`
	width: 100%;
	height: 100px;
	overflow: hidden;
	position: relative;
	gap: 16px;
	display: flex;
	justify-content: start;
	align-items: center;

	padding: 32px 24px;
	border-radius: 12px;
	background: #f7f7f7;

	transition: 0.1s all;

	svg {
		display: none;
	}

	&:hover {
		cursor: pointer;
		text-decoration: none;
		background: ${themed(({ background }) => background.light)};
	}

	&:focus-visible {
		outline: ${theme.button?.primary?.focus?.outline};
		border-radius: 12px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		height: 68px;
		gap: 12px;
		padding: 16px 12px;
	}
`;
export const StyledShortcutDiv = styled(StyledShortcut.withComponent('div'))`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 40px;
	width: 40px;
	height: 40px;

	background: ${(props) => props.color};
	border-radius: 50%;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		min-width: 24px;
		width: 24px;
		height: 24px;
	}
`;

export const StyledImage = styled.img<{ background?: string }>`
	margin: 5px;
	background: ${(props) => props.background};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		min-width: 10px;
		width: 10px;
		height: 14px;
	}
`;
interface IShortcutTitleProps {
	color: string;
}

export const ShortcutTitle = styled(H3)<IShortcutTitleProps, ITheme>`
	margin: 0;
	color: ${(props) => props.color};
	font-size: 21px;
	line-height: 25px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		font-size: 18px;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		font-size: 15px;
		line-height: 18px;
		margin-right: 10px;
	}
`;
export const ShortcutText = styled.span<IShortcutTitleProps, ITheme>`
	margin: 0;
	color: ${(props) => props.color};
	font-size: 18px;
	line-height: 22px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		font-size: 16px;
		line-height: 18px;
	}
`;

export const NewShortcutTag = styled.div`
	width: 100px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	padding: 5px 10px;

	top: 10%;
	right: -10%;
	transform: rotate(45deg);

	text-align: center;
	color: black;
	background-color: ${Color.AlertLight};
	border-radius: 5px;

	font-weight: 600;
	font-size: 12px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		right: -6%;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		height: 24px;
		right: -18%;

		font-size: 12px;
		line-height: 16px;
	}

	@media (max-width: 350px) {
		width: 90px;
		height: 20px;
		right: -22%;
		font-size: 10px;
	}
`;
