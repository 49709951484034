import React from 'react';
import {
	NewShortcutTag,
	ShortcutTitle,
	StyledImage,
	StyledImageContainer,
	StyledShortcut,
} from './StyledShortcut';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { getLinkPropsForUrl } from '../../../shared/components/link/utils/getLinkPropsForUrl';

interface IShortcutProps {
	href: string;
	title: string;
	color: string;
	icon: string;
	isNew?: boolean;
}

export const Shortcut = ({
	href,
	title,
	color,
	icon,
	isNew = false,
}: IShortcutProps) => {
	const { t } = useTranslation();

	const hrefProps = getLinkPropsForUrl(href);
	const isExternalLink = 'target' in hrefProps;

	return (
		<StyledShortcut
			aria-label={t('shortcut_aria_label', {
				title,
				external: isExternalLink ? t('new_window_aria_label') : '',
			})}
			{...hrefProps}
		>
			<StyledImageContainer color={color}>
				<StyledImage src={icon} alt="" role="presentation" background={color} />
			</StyledImageContainer>
			<ShortcutTitle styling="h4" color={color}>
				{title}
			</ShortcutTitle>
			{isNew && (
				<NewShortcutTag>
					{t('account_overview_new_shortcut_label')}
				</NewShortcutTag>
			)}
		</StyledShortcut>
	);
};
